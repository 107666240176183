import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import Image from 'next/image'
import {clsx} from 'clsx'
import {useEffect, useState} from 'react'
import {useTrustArcCookieValue} from '@/utils/analytics'
import type {BlockWithClassName} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {loadImageFromKontentAI} from '@/imageLoader'
import {VideoBlock} from '@/_new-code/products/flexible-web-toolkit/components/video-block/video-block'
import {FallbackBlock} from '../fallback-block'
import {spacing} from '../../../styles'

export type VideoBlockContentItem = IContentItem<{
	videoTitle: Elements.TextElement
	videoDescription: Elements.TextElement
	videoId: Elements.TextElement
	title: Elements.TextElement
	transcript: Elements.RichTextElement
	posterFrameImage: Elements.AssetsElement
	tabTitle: Elements.TextElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	cookieFallbackImage: Elements.AssetsElement
}>

export const VideoBlockBlock: BlockWithClassName<VideoBlockContentItem> = ({
	block,
	className,
	...context
}) => {
	const spacingChoice =
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		]
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary, spacingChoice may be null
	const componentSpacing = !spacingChoice ? spacing.default : spacingChoice
	const hasTranscript = block.elements.transcript.value !== '<p><br></p>'
	const trustArcCookie = useTrustArcCookieValue()
	const [privacyMode, setPrivacyMode] = useState(false)
	useEffect(() => {
		if (trustArcCookie === '1:') {
			setPrivacyMode(true)
		}
	}, [trustArcCookie])
	return (
		<div className={clsx(`flex justify-center`, componentSpacing)}>
			{trustArcCookie === '0:' ? (
				<>
					{block.elements.cookieFallbackImage[0] ? (
						<Image
							alt={
								block.elements.cookieFallbackImage[0]
									.description ?? ''
							}
							height={
								block.elements.cookieFallbackImage[0].height ||
								720
							}
							loader={loadImageFromKontentAI}
							src={block.elements.cookieFallbackImage[0].url}
							width={
								block.elements.cookieFallbackImage[0].width ||
								1280
							}
						/>
					) : (
						<FallbackBlock />
					)}
				</>
			) : (
				<div
					className={
						className
							? className
							: clsx(
									componentSpacing !== spacing.none
										? 'mx-12'
										: null,
									`w-full md:w-7/12 lg:w-full`
								)
					}
				>
					{Boolean(block.elements.videoTitle) && (
						<h1>{block.elements.videoTitle}</h1>
					)}
					{Boolean(block.elements.videoDescription) && (
						<p className="my-6">
							{block.elements.videoDescription}
						</p>
					)}
					<VideoBlock
						posterFrameImageUrl={
							block.elements.posterFrameImage[0]?.url
						}
						privacyMode={privacyMode}
						title={
							<span data-kontent-element-codename="title">
								{block.elements.title}
							</span>
						}
						transcript={Boolean(hasTranscript)}
						videoId={block.elements.videoId}
					>
						{Boolean(hasTranscript) && (
							<RichTextRenderer
								className="text-blue-900"
								element={block.elements.transcript}
								{...context}
							/>
						)}
					</VideoBlock>
				</div>
			)}
		</div>
	)
}
